<template>
    <div class="magazin__item noselect">
      <span @click="handleArticleClick(article)">
        <img :src="'https://app.sportlerplus.com/scaled/800/' + article.main_image_url" class="magazin__item__image">
        <div class="magazin__item__badge">{{ article.media_type_names }}</div>
        <div class="magazin__item__text">
          <div class="magazin__item__title">{{ article.title }}</div>
          <div class="magazin__item__copy">{{ article.intro }}</div>
          <div class="magazin__item__meta">{{ articleReadTime() }}</div>
        </div>
      </span>
    </div>
</template>
<script>
export default {
    name: 'ArticleItem',
    props: {
        article: {
            type: Object,
            default() { return {}; } 
        }
    },
    computed: {
        premiumStatus() {
            return this.$store.getters.getPremiumStatus;
        },
        readArticleList() {
            return this.$store.getters.getReadArticleList;
        }
    },
    methods: {
        handleArticleClick(article) {
            if (article.premium_content && Object.keys(this.premiumStatus).length === 0) {
                this.$emit('showPremiumHint');
            }
            else {
                this.$router.push("/articles/" + article.id);
            }
        },
        articleReadTime() {
            if (this.readArticleList.indexOf(this.article.id) != -1) {
                return "Bereits gelesen";
            }
            return this.article.readtime + " Min.";
        },
    }
}
</script>