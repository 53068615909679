<template>
    <div v-if="sportsTypeId == -1" class="noselect">
        <!-- Hint Text -->
        <div class="text-center">
          <small>Hier findest du Workouts & Artikel, die von unseren Coaches speziell für <router-link to="/settings/sportstypes">deine Sportarten</router-link> zusammengestellt wurden:</small>
        </div>

        <div class="section" v-for="sportsType in userSportsTypes" v-bind:key="sportsType.id">
            <router-link :to="'/playlists/sportstype/' + sportsType.id + '?navbartitle=' + sportsType.name">
                <div class="card">
                    <!-- <div class="card__premium"><img src="@/assets/ic_20_premium.svg" alt=""></div> -->
                    <div class="card__title">{{ sportsType.name }}</div>
                    <div class="card__subtitle"></div>
                    <img v-if="sportsType.image_url"  class="card__image" :src="'https://app.sportlerplus.com/scaled/800/' + sportsType.image_url" alt="" />
                </div>
            </router-link>
        </div>
    </div>

    <div v-else class="noselect">
        <div v-if="selectedSportsType && selectedSportsType.about" class="text-center">
          <small v-html="selectedSportsType.about"></small>
        </div>
        <!-- TABS -->
        <div class="tabs tabs--fullwidth">
            <div class="tabs__content">
                <v-btn v-if="trainingItems.length > 0" @click="displayTab('workouts')" :class="activeDisplay=='workouts' ? 'is-active' : ''" text v-ripple="false">Workouts</v-btn>
                <v-btn v-if="articles.length > 0" @click="displayTab('articles')" :class="activeDisplay=='articles' ? 'is-active' : ''" text v-ripple="false">Magazin</v-btn>
            </div>
        </div>

        <!-- Workouts -->
        <div v-show="activeDisplay=='workouts'" class="tabs__item">
            <template v-for="ti in trainingItems">
                <WorkoutItem v-if="activityType(ti) == 'workout.WorkoutPage'"  @showPremiumHint=showPremiumHint :workout="ti" v-bind:key="ti.id"></WorkoutItem>
                <PlaylistItem v-else-if="activityType(ti) == 'exercise.PlaylistPage'" @showPremiumHint=showPremiumHint :playlist="ti" v-bind:key="ti.id"></PlaylistItem>
            </template>
        </div>

        <!-- Magazin -->
        <div v-show="activeDisplay=='articles'" class="tabs__item">
            <ArticleItem @showPremiumHint=showPremiumHint v-for="article in articles" :key="article.id" :article="article"></ArticleItem>
        </div>


        <PremiumHintModal></PremiumHintModal>
    </div>
</template>

<script>
import WorkoutItem from '@/components/WorkoutItem.vue';
import ArticleItem from '@/components/ArticleItem.vue';
import PlaylistItem from '@/components/PlaylistItem.vue';
import PremiumHintModal from '@/components/PremiumHintModal.vue';

export default {
    name: 'PlaylistsBySportsType',
    data() {
        return {
            sportsTypeId: -1,
            playlists: [],
            articles: [],
            workouts: [],
            sportsTypes: [],    // if parameter == -1, list user sports types
            userSportsTypes: [],
            activeDisplay: "workouts",
            selectedSportsType: null,
        }
    },
    components: {
        WorkoutItem, ArticleItem, PlaylistItem, PremiumHintModal,
    },
    beforeRouteUpdate (to, from, next) {
        this.sportsTypeId = to.params.sportsTypeId;
        this.init();
        next();
    },
    methods: {
        /* eslint-disable no-unused-vars */
        init() {
            if (this.sportsTypeId == -1) {
                this.fetchUserSportsTypes();
            }
            else {
                this.$store.dispatch('fetchSportsTypes').then( () => {
                    this.sportsTypes = this.$store.getters.getSportsTypes;
                    this.selectedSportsType = this.sportsTypes.find(el => el.id == this.sportsTypeId);
                });

                this.$store.dispatch('fetchPlaylists').then( () => {
                    this.playlists = this.$store.getters.getPlaylists;
                    this.playlists = this.playlists.filter(pl => {
                        if (pl.sports_types) {
                            if (pl.sports_types.find(st => st.id == this.sportsTypeId)) return true;
                        }
                        return false;
                    });
                });

                this.$store.dispatch('fetchWorkouts').then( () => {
                    this.workouts = this.$store.getters.getWorkouts;
                    this.workouts = this.workouts.filter(wo => {
                        if (wo.sports_types) {
                            if (wo.sports_types.find(st => st.id == this.sportsTypeId)) return true;
                        }
                        return false;
                    });
                });

                this.$store.dispatch('fetchArticles').then( () => {
                    this.articles = this.$store.getters.getArticles;
                    this.articles = this.articles.filter(ai => {
                        if (ai.sports_types) {
                            if (ai.sports_types.find(st => st.id == this.sportsTypeId)) return true;
                        }
                        return false;
                    });
                });

            }
        },
        displayTab(name) {
            this.activeDisplay = name;
        },
        showPremiumHint() {
            this.$modal.show("premiumHint");
        },
        async fetchUserSportsTypes() {
            await this.$store.dispatch('fetchSportsTypes').then( () => {
                this.sportsTypes = this.$store.getters.getSportsTypes;
            });
            await this.$store.dispatch('fetchUserInformation').then( (res) => {
                if (res.length != 0) {
                    this.userInformation = res[0];

                    if (this.userInformation && this.userInformation.sports_types) {
                        this.userSportsTypes = [...this.userInformation.sports_types];
                    }                    
                }
            });
            this.sportsTypes.filter(st => !st.user_selectable).forEach(st => this.userSportsTypes.push(st));
            this.userSportsTypes.forEach(ust => {
                let tempST = this.sportsTypes.find(st => st.id == ust.id);
                if (tempST) {
                    ust.image_url = tempST.image_url;
                }
            });
        },
        activityType(activity) {
			if (!activity) { return null; }
			if (activity.meta === undefined) { return "Category"; }
			else { return activity.meta.type; }
		},
    },
    mounted() {
        this.sportsTypeId = this.$route.params.sportsTypeId;
        this.init();
    },
    computed: {
        premiumStatus() {
            return this.$store.getters.getPremiumStatus;
        },
        trainingItems() {
            var trainingItems = this.playlists.concat(this.workouts);
            return trainingItems.sort((a, b) => {
                var d1 = new Date(a.go_live_at);
                var d2 = new Date(b.go_live_at);
                return d2 - d1;
             })
        }
    },
}
</script>

<style lang="scss" scoped>
    small {
        display: inline-block;
        line-height: 20px;
        margin-top: 4px;
        margin-bottom: 27px;

        a {
            color: $colorPrimary !important;
            text-decoration: underline;
        }
    }

    a {
        text-decoration: none;
    }
</style>